<template>
  <div class="os-container">
    <reload-button
      v-show="activeTab === 'overview'"
      @click="overviewRefresh"
      :show-text="false"
      style="position: absolute; right: 16px; top: 13px; z-index: 999"
    ></reload-button>
    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="overview" tab="总览">
        <a-row :gutter="16">
          <a-col :xs="24" :md="12" :xl="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                系统状态统计
              </div>
              <status-card
                type="windows"
                source-type="os"
                :countFunc="countFunc"
                @severityData="v => (windowsChartData = v)"
                @changeList="getParams({os_type: 'windows'})"
                @filterByStatus="v => getParams({os_type: 'windows', status: v})"
                @filterAlerts="v => currentSeverity = v"
              >
                <template slot="typeImg">
                  <img
                    src="~@/assets/icons/windows.svg"
                    width="48"
                    height="48"
                  />
                </template>
              </status-card>
            </div>
          </a-col>
          <a-col :xs="24" :md="12" :xl="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                系统状态统计
              </div>
              <status-card
                type="linux"
                source-type="os"
                :countFunc="countFunc"
                @changeList="getParams({os_type: 'linux'})"
                @filterByStatus="v => getParams({os_type: 'linux', status: v})"
                @filterAlerts="v => currentSeverity = v"
              >
                <template slot="typeImg">
                  <img src="~@/assets/icons/linux.svg" width="48" height="48" />
                </template>
              </status-card>
            </div>
          </a-col>
          <a-col :md="24" :xl="12" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                版本分布统计
              </div>
              <version-table></version-table>
            </div>
          </a-col>
          <!-- 当前告警 -->
          <a-col :md="24" :lg="14" :xl="16" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header" style="margin-bottom: 16px">
                当前告警
              </div>
              <severity-radio-group
                style="position: absolute; top: 16px; right: 20px"
                v-model="currentSeverity"
              ></severity-radio-group>
              <alert-timeline
                :severity="currentSeverity"
                sourceType="os"
                style="padding: 10px 4px 0 10px; overflow: auto; height: 265px"
                class="overflow-scroll-hidden"
              ></alert-timeline>
            </div>
          </a-col>
          <a-col :md="24" :lg="10" :xl="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header">累计告警统计</div>
              <div style="position: absolute; right: 20px; top: 17px">
                <simple-select
                  :options="datetimeOptions"
                  :value="datetimeOptions[1]"
                  @input="v => (selectedDatetime = v.value)"
                ></simple-select>
              </div>
              <a-spin :spinning="spinning['alert']">
                <radial-bar-chart
                  v-if="chartData.length"
                  chart-id="os-severity-chart"
                  :chart-data="chartData"
                  legendPosition="right"
                  :flip-page="false"
                  :maxValue="null"
                  :height="270"
                  unit=""
                  :barSize="11"
                  :colors="severityColors"
                  :legendPositionCenter="true"
                  :annotationHtml="annotationHtml"
                  :style="{
                    paddingTop: '26px',
                    maxHeight: '270px',
                    overflow: 'hidden'
                  }"
                ></radial-bar-chart>
                <div v-else style="height: 270px; position: relative; overflow: hidden;">
                  <empty-component
                    :body-style="{
                      height: '216px',
                      marginTop: '36px'
                    }"
                    :description-style="{
                      marginTop: '-28px'
                    }"
                  ></empty-component>
                </div>
              </a-spin>
            </div>
          </a-col>
          <a-col style="margin-bottom: 16px">
            <div class="module-container" style="min-height: 410px">
              <div class="module-header" style="margin-bottom: 16px">
                性能高风险主机
              </div>
              <high-performance-table
                source-type="os"
                :scrollY="608"
                :get-func="getPerfFunc"
              ></high-performance-table>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="list" tab="列表详情">
        <div
          style="
            background: #fff;
            border-radius: 8px;
            padding: 24px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
            position: relative;
          "
        >
          <os-table
            :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
            :fetch-params="fetchParams"
          ></os-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moment from 'moment'
import { blueColor, redColor, orangeColor, osTypes } from '@/utils/const'
import {
  getOSAlertCount,
  getOSCount,
  getPerformanceOSList
} from '@/api/os'
import OSTable from '@/components/table/OSTable/index.vue'
import VersionTable from './modules/VersionTable'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import RadialBarChart from '@/components/chart/RadialBarChart'
import HighPerformanceTable from '@/components/source-overview-page/HighPerformanceTable.vue'
import ReloadButton from '@/components/button/ReloadButton.vue'
import SimpleSelect from '@/components/select/SimpleSelect'

const imgUrl = require('@/assets/icons/alarm.svg')

export default {
  name: 'OS',
  components: {
    'os-table': OSTable,
    AlertTimeline: () => import('@/components/timeline/AlertTimeline.vue'),
    StatusCard: () => import('@/components/source-overview-page/StatusCard.vue'),
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    VersionTable,
    SeverityRadioGroup,
    HighPerformanceTable,
    RadialBarChart,
    ReloadButton,
    SimpleSelect
  },
  computed: {
    tabList () {
      const arr = [{ title: '全部', value: 'all' }]
      osTypes.forEach(value => {
        arr.push({ value, title: this.$t(`os_type.${value}`) })
      })
      return arr
    }
  },
  inject: ['reload'],
  data () {
    return {
      countFunc: getOSCount,
      activeTab: 'overview',
      currentSeverity: 'all',
      severityColors: [redColor, orangeColor, blueColor, '#38D3FA'],
      windowsChartData: undefined,
      linuxChartData: undefined,
      getPerfFunc: getPerformanceOSList,
      chartData: [],
      datetimeOptions: [
        { label: '全部', value: 'all' },
        { label: '近 7 天', value: 'week' },
        { label: '近 30 天', value: 'month' }
      ],
      selectedDatetime: 'week',
      annotationHtml: `<div style="transform:translate(-50%,-50%); opacity: 0.75"><img width="46" height="46" src=${imgUrl}></div>`,
      fetchParams: {},
      spinning: {
        alert: false
      }
    }
  },
  mounted () {
    this.fetchAlerts()
  },
  methods: {
    fetchAlerts (datetimes = [moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm')]) {
      this.spinning.alert = true
      const params = {
        count_of: 'severity'
      }
      if (datetimes !== false) {
        params.datetime_from = datetimes[0]
        params.datetime_to = datetimes[1]
      }
      getOSAlertCount(params).then(res => {
        const arr = [
          { name: 'disaster', value: 0 },
          { name: 'high', value: 0 },
          { name: 'average', value: 0 },
          { name: 'warning', value: 0 }
        ]
        if (res.data.data && res.data.data.length) {
          for (let i = 0; i < res.data.data.length; i++) {
            const item = res.data.data[i]
            const index = arr.findIndex(alert => alert.name === item.name)
            if (index !== -1) arr[index].value = item.value
          }
        }
        this.chartData = arr.map(item => {
          return {
            name: this.$t(`alert_severity.${item.name}`),
            value: item.value
          }
        })
      }).finally(() => { this.spinning.alert = false })
    },
    overviewRefresh () {
      this.reload()
    },
    getParams (params) {
      this.fetchParams = params
      this.activeTab = 'list'
    }
  },
  watch: {
    selectedDatetime (v) {
      if (v === 'month') {
        this.fetchAlerts([moment().subtract(29, 'day').format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm')])
      } else if (v === 'all') {
        this.fetchAlerts(false)
      } else {
        this.fetchAlerts()
      }
    }
  }
}
</script>

<style lang="less">
.os-container {
  padding: 0 6px;

  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }
}
</style>
