<template>
  <div class="version-table">
    <a-table
      class="custom-table scroll-hidden-table"
      :columns="columns"
      :data-source="tableData"
      :row-key="record => record.name ?  record.name :  record.value"
      :pagination="false"
      :scroll="{ x: scrollX, y: 230 }"
      style="margin-top: 16px"
    >
      <template slot="name" slot-scope="text">
        <content-tooltip v-if="text" :title="text" :body="text"></content-tooltip>
        <span v-else>-</span>
      </template>

      <template slot="status" slot-scope="text, record">
        <a-space size="middle">
          <div
            v-for="severity in sourceStatuses"
            :key="severity"
            :style="{
              width: '28px',
              height: '28px',
              border: '1px solid',
              borderColor: getStatusColor(severity),
              color: getStatusColor(severity),
              background: getStatusColor(severity) + '10',
              borderRadius: '4px',
              textAlign: 'center',
              lineHeight: '28px'
            }"
          >
            <a-tooltip :title="$t(`source_status.${severity}`)">{{ getData(severity,record.value) }}</a-tooltip>
          </div>
        </a-space>
        <!-- <span v-else>-</span> -->
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  greenColor,
  redColor,
  orangeColor,
  sourceStatuses
} from '@/utils/const'
import { getOSCount } from '@/api/os'
import ContentTooltip from '@/components/ContentTooltip.vue'

export default {
  name: 'VersionTable',
  components: {
    ContentTooltip
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      sourceStatuses,
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 120,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'value',
          title: '数量',
          width: 80,
          align: 'center'
        }
        // {
        //   dataIndex: 'status',
        //   title: '状态',
        //   width: 200,
        //   scopedSlots: {
        //     customRender: 'status'
        //   }
        // }
      ],
      tableData: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getOSCount({ count_of: 'version' }).then(res => {
        this.tableData = res.data.data
      })
    },
    getStatusColor (status) {
      if (status === 'normal') return greenColor
      else if (status === 'abnormal') return redColor
      else if (status === 'alert') return orangeColor
      return '#a1a1a1'
    },
    getData (severity, arr) {
      // const obj = arr.find(item => item.name === severity)
      // if (obj) return obj.value
      if (severity === 'unknown') return arr
      else return 0
      // return 0
    }
  }
}
</script>

<style lang="less" scoped>
.version-table {
  .ant-table .ant-table-content {
    .ant-table-tbody > tr {
      height: 44px;
      max-height: 44px;

      & > td {
        padding: 8px 16px;
      }
    }
    & .ant-table-thead > tr > th {
      height: 48px !important;
    }
  }
}
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
