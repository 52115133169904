var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version-table"},[_c('a-table',{staticClass:"custom-table scroll-hidden-table",staticStyle:{"margin-top":"16px"},attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"row-key":record => record.name ?  record.name :  record.value,"pagination":false,"scroll":{ x: _vm.scrollX, y: 230 }},scopedSlots:_vm._u([{key:"name",fn:function(text){return [(text)?_c('content-tooltip',{attrs:{"title":text,"body":text}}):_c('span',[_vm._v("-")])]}},{key:"status",fn:function(text, record){return [_c('a-space',{attrs:{"size":"middle"}},_vm._l((_vm.sourceStatuses),function(severity){return _c('div',{key:severity,style:({
            width: '28px',
            height: '28px',
            border: '1px solid',
            borderColor: _vm.getStatusColor(severity),
            color: _vm.getStatusColor(severity),
            background: _vm.getStatusColor(severity) + '10',
            borderRadius: '4px',
            textAlign: 'center',
            lineHeight: '28px'
          })},[_c('a-tooltip',{attrs:{"title":_vm.$t(`source_status.${severity}`)}},[_vm._v(_vm._s(_vm.getData(severity,record.value)))])],1)}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }